exports.components = {
  "component---src-templates-categories-template-tsx": () => import("./../../../src/templates/categories-template.tsx" /* webpackChunkName: "component---src-templates-categories-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/category-template.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-index-template-tsx": () => import("./../../../src/templates/index-template.tsx" /* webpackChunkName: "component---src-templates-index-template-tsx" */),
  "component---src-templates-not-found-template-tsx": () => import("./../../../src/templates/not-found-template.tsx" /* webpackChunkName: "component---src-templates-not-found-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post-template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/tag-template.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */),
  "component---src-templates-tags-template-tsx": () => import("./../../../src/templates/tags-template.tsx" /* webpackChunkName: "component---src-templates-tags-template-tsx" */)
}

